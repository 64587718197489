/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import './responsive.scss';
@import '../../node_modules/font-awesome/css/font-awesome.css';

.component-container {

    @apply absolute inset-0 flex-auto flex-col min-w-0 overflow-y-auto;

    .header-container {
        @apply flex items-center justify-between w-full sm:items-center p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent;

        .title-container {
            @apply flex min-w-0;

            .title {
                @apply text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate;
            }
        }

        .actions-container {
            @apply flex items-center justify-end sm:ml-4;
        }

        .title-container-filter {
            @apply flex items-center justify-between w-full;

            .title-container {
                @apply flex min-w-0;

                .title {
                    @apply text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate;
                }
            }

            .filter-container {
                @apply hidden md:flex w-80 justify-center items-center flex-shrink-0;

                .mat-form-field-container {
                    @apply w-60 flex-auto;

                    .mat-mdc-form-field-subscript-wrapper {
                        @apply hidden;
                    }
                }

                .title {
                    @apply text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate;
                }
            }

            .actions-container {
                @apply items-center justify-end sm:ml-4;
            }

        }

    }

    .main-container {
        @apply flex-auto p-5 sm:p-5;

        .filter-container {
            @apply flex-auto mb-5;

            .mat-expansion-panel-content {
                .mat-expansion-panel-body {
                    @apply flex flex-col;

                    .search-form {
                        @apply flex flex-col m-2;

                        .gap-container {
                            @apply flex flex-auto flex-col w-full gap-y-2;

                            .fields-container {
                                @apply grid gap-x-6 gap-y-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4;

                                .mat-form-field-container {
                                    @apply w-full flex-auto;
                                }
                            }

                            .buttons-container {
                                @apply grid gap-x-6 gap-y-4 grid-cols-1 text-center md:grid-cols-3 lg:grid-cols-4;
                            }
                        }
                    }
                }
            }
        }

        .content-container {
            @apply sm:col-span-6 flex flex-col flex-auto p-5 bg-card shadow rounded-2xl overflow-hidden;

            .table-container {
                @apply flex flex-col flex-auto mt-2 overflow-x-auto;

                .table {
                    @apply overflow-auto w-full;
                }

            }

            .mat-mdc-paginator {
                .mat-mdc-paginator-outer-container {

                    .mat-mdc-paginator-container {
                        @apply p-0;

                        .mat-mdc-paginator-page-size {
                            margin: 0 0;
                        }

                        .mat-mdc-paginator-range-actions {
                            margin: 0 0;
                        }
                    }
                }
            }
        }
    }

    .form-container {
        @apply relative flex flex-col m-0 p-5 bg-card rounded-2xl shadow overflow-hidden;

        .gap-container {
            @apply flex flex-auto flex-col w-full gap-y-2;

            .fields-container {
                @apply grid gap-x-6 gap-y-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4;

                .mat-form-field-container {
                    @apply w-full flex-auto;
                }

            }

            .buttons-container {
                @apply grid gap-x-6 gap-y-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4;
            }
        }

        .config-button {
            @apply absolute top-0 right-0 mt-2 mr-2;
        }
    }

    .content-container-table {
        @apply sm:col-span-6 flex flex-col flex-auto m-5 p-5 bg-card rounded-2xl shadow overflow-hidden;

        .table-container {
            @apply flex flex-col flex-auto mt-2 overflow-x-auto;

            .table-header {
                @apply flex items-center justify-between w-full sm:items-center mb-5;

                .table-title {
                    @apply flex text-3xl md:text-2xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate;
                }

                .table-buttons {
                    @apply flex justify-end mt-2;
                }
            }

            .table {
                @apply overflow-y-visible w-full overflow-x-auto;
            }
        }
    }
}

.component-panel-container {
    @apply absolute inset-0 min-w-0 overflow-y-auto;

    .header-container {
        @apply flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent;

        .title-container {
            @apply flex-1 min-w-0;

            .title {
                @apply text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate;
            }

            .subtitle {
                @apply md:text-2xl tracking-tight truncate;
            }
        }

        .actions-container {
            @apply flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4;

            .back-btn {
                @apply bg-card mr-5;
            }
        }
    }

    .main-drawer-container {
        @apply rounded-t-2xl -mt-16 shadow overflow-hidden;

        .sidebar-drawer-container {
            @apply w-72 dark:bg-gray-900;
        }

        .main-drawer-content {
            @apply flex flex-col;

            .main-drawer {
                @apply flex-auto p-6 sm:p-10;

                .main-content {
                    @apply h-180 min-h-180 max-h-200;
                }
            }
        }
    }
}



.panel-container {
    @apply absolute inset-0 min-w-0 overflow-y-auto;

    .main-container {
        @apply flex-auto m-8 pb-4;

        .content-container {
            @apply sm:col-span-6 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden;

            .table-container {
                @apply flex flex-col flex-auto mt-2 overflow-x-auto;

                .table {
                    @apply min-w-240 overflow-y-visible;
                }
            }
        }
    }

    .form-container {
        @apply flex flex-col m-8 pb-4 overflow-hidden;

        .gap-container {
            @apply flex flex-auto flex-col w-full gap-y-2;

            .fields-container {
                @apply grid gap-x-6 gap-y-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4;

                .mat-form-field-container {
                    @apply w-full flex-auto;
                }
            }

            .fields-container-full {
                @apply grid gap-x-6 gap-y-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-1;

                .mat-form-field-container {
                    @apply w-full flex-auto;
                }
            }

            .buttons-container {
                @apply grid gap-x-6 gap-y-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4;
            }
        }
    }
}

.modal-container {
    .modal-title {
        @apply text-2xl md:text-3xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate;
    }

    .modal-content-container {
        @apply flex flex-col p-6 overflow-y-auto;

        &.row {
            @apply flex-row;
        }

        .form-container {
            @apply flex flex-auto flex-col w-full;

            .gap-container {
                @apply grid gap-x-6 gap-y-2 grid-cols-1 md:grid-cols-1 lg:grid-cols-2;

                .mat-form-field-container {
                    @apply w-full flex-auto;
                }
            }

            .buttons-form-container {
                @apply flex flex-auto justify-end;
            }

            .buttons-container {
                @apply justify-center;
            }
        }

        .table-container {
            @apply flex flex-col flex-auto mt-10 overflow-x-auto;

            .table {
                @apply overflow-auto w-full;
            }

        }

        .calendar-event-container {
            .calendar-event-label {
                @apply font-extrabold;
            }

            .calendar-event-content {
                @apply mb-3;
            }
        }

        // two columns
        .first-container {
            @apply flex-1;
        }

        .second-container {
            @apply flex-1 max-h-100 pl-5;
        }
    }
}

.mat-slide-toggle-container {
    @apply w-full flex-auto;

    .mat-slide-toggle-content {
        @apply flex flex-col;

        .label-slide-toggle {
            @apply w-full flex-auto mb-4 text-gray-700 dark:text-gray-200;
            font-weight: 500;
        }

        .slide-toggle {
            @apply w-full flex-auto text-center;
        }
    }
}

.row-highlight {
    border: 2px solid red;
}

.old-date-row {
    background-color: #f0f0f0 !important;
    /* gris clarito */
}

.grey-row {
    background-color: #f0f0f0 !important;
    /* gris clarito */
}

.project-status {
    @apply inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase;

    &.confirmed {
        @apply bg-green-200 text-green-800;
    }

    &.pending {
        @apply bg-yellow-500 text-white;
    }

    &.execution {
        @apply bg-blue-800 text-white;
    }

    &.cancelled {
        @apply bg-red-900 text-white;
    }

    &.reserved {
        @apply bg-blue-200 text-blue-800;
    }

    &.finalized {
        @apply bg-green-600 text-green-50;
    }

    &.released {
        @apply bg-gray-200 text-gray-800;
    }

    &.default {
        @apply bg-gray-900 text-white;
    }
}

.payment-status {
    @apply inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase;

    &.in-time {
        @apply bg-blue-200 text-blue-800;
    }

    &.expired {
        @apply bg-red-200 text-red-800;
    }

    &.close-to-expire {
        @apply bg-yellow-200 text-yellow-800;
    }

    &.paid {
        @apply bg-green-200 text-green-800;
    }

    &.default {
        @apply bg-gray-200 text-gray-800;
    }
}

.calendar-event {
    cursor: pointer;
}

.legend-container {
    @apply flex flex-col gap-y-2;

    .legend-item {
        @apply inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase;

        .legend-item-label {
            @apply text-sm;
        }
    }
}

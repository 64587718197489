@media only screen and (max-width: 768px) {

    app-calendar {
        .fc .fc-toolbar-title {
            font-size: 1.25rem;
        }

        .fc-button-group {

            .fc-dayGridMonth-button,
            .fc-multiMonth-button,
            .fc-listYear-button {
                display: none;
            }
        }
    }

}

@media (max-width: 1440px) {
    .component-panel-container {

        .main-drawer-container {
            height: 100vh;
        }
    }
}